import React from 'react'
import { graphql } from 'gatsby'

import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Page from '../components/page-inner/page'
import Layout from '../components/layout'

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    sanityPage(id: { eq: $id }) {
      title
      metaTitle
      metaDescription
      topBarTitle
      _rawContentSections(resolveReferences: { maxDepth: 10 })
    }
  }
`

const PageTemplate = props => {
  const { data, errors } = props
  const sanityPage = data && data.sanityPage
  const title = sanityPage.topBarTitle
    ? sanityPage.topBarTitle
    : sanityPage.title
    ? sanityPage.title
    : ''
  const metaTitle = data.sanityPage.metaTitle || title
  const metaDescription = data.sanityPage.metaDescription

  return (
    <Layout title={title} {...props}>
      {errors && <SEO title="GraphQL Error" />}
      {sanityPage && <SEO title={metaTitle} description={metaDescription} />}

      {errors && <GraphQLErrorList errors={errors} />}

      {sanityPage && <Page {...sanityPage} location={props.location} />}
    </Layout>
  )
}

export default PageTemplate

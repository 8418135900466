import React from 'react'

import SectionsChooser from '../sections-chooser'

const PageContent = props => {
  const { _rawContentSections } = props

  return (
    <>
      {/* Dynamic sections */}
      <SectionsChooser sections={_rawContentSections} location={props.location} />
    </>
  )
}

export default PageContent
